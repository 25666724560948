<template>
	<page>
		<module enable-flex>
			<c-form ref="productBomForm" unit-width="100%">
				<c-form-item label="商品编码" width="400">
					<div class="flex-center">
						<c-input name="product_code"></c-input>
						<c-button color="sys" style="margin-left: 10px;" @click="searchHandle">查询</c-button>
					</div>
				</c-form-item>
				<c-form-item label="商品名称" width="400">
					<c-input name="product_name" disabled></c-input>
				</c-form-item>
				<c-form-item label="商品基数" width="300" required>
					<c-input name="product_count"disabled></c-input>
				</c-form-item>
			</c-form>
			<c-table ref="table" height="grow" :paging="false" :data="material_list" >
				<c-table-column
					type="expand"
					label="物料名称"
					name="material_name"
					:add-button="false"
					expand-limit="10"
					width="300"
					@expand="loadChildren"
				>
				</c-table-column>

<!--				<c-table-column
					label="物料编码"
					name="material_code"
				>
				</c-table-column>-->

				<c-table-column
					label="物料单位"
					name="material_unit"
				>
				</c-table-column>

				<c-table-column
					label="物料数量"
					name="material_count"
				>
				</c-table-column>
				
				<c-table-column
					label="当前库存"
					name="stock_count"
				>
				</c-table-column>
			</c-table>
		</module>
	</page>
</template>

<script>
export default {
	name: 'm_bom_module',
	components: {
	},
	data() {
		return {
			material_list:[]
		}
	},
	computed: {
	},
	mounted() {
		// this.$refs.search.search();
	},
	methods: {
		searchHandle(){
			var data = this.$refs.productBomForm.get();
			if (!data.product_code||data.product_code.trim()==''){
				this.$message({
					message: '请输入商品/物料编码'
				});
				return;
			}
			this.request({
				url: '/product/info',
				data: {
					product_code: data.product_code
				},
				success: data => {
					this.request({
						url: '/bom/detail',
						data: {
							product_id: data.id
						},
						success: data2 => {
							this.$refs.productBomForm.clear();
							this.$refs.productBomForm.set(data2.product_info);
							if (data2.material_list && data2.material_list.length > 0){
								this.material_list = data2.material_list;
							}else {
								this.$message({
									message: '当前商品/物料无组件信息'
								});
								return;
							}
						}
					});
				}
			});
		},
		loadChildren(row, resolve) {
			this.request({
				url: '/bom/detail',
				data: {
					product_id: row.data.material_id,
					product_count:row.data.material_count
				},
				success: data => {
					resolve(data.material_list);
				}
			});
		}
	}
}
</script>